import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    TaskGroupCreatePayload,
    TaskGroupIdPayload,
    TaskGroupListPayload,
    TaskGroupListResponse,
    TaskGroupUpdatePayload,
    remove,
    update,
    TaskGroupListPointsResponse,
    listPoints,
    vehiclePlannings,
    VehiclePlanningAvailabilitiesResponse,
    VehiclePlanningAvailabilitiesPayload,
    vehicleAvailabilities,
    TaskGroupListServicesResponse,
    TaskGroupListServicesPayload,
    listServices,
    TaskGroupCreateServicePayload,
    createService,
    importFile,
    TaskGroupImportFilePayload,
    TaskGroupListAvailableOperatorsResponse,
    listAvailableOperators,
    TaskGroupListAvailableVehiclesResponse,
    listAvailableVehicles,
    TaskGroupListAvailableVehiclesPayload,
    TaskGroupListAvailableOperatorsPayload,
    unplan,
    TaskGroupAvailableOperatorResponse,
    TaskGroupAvailableOperatorPayload,
    TaskGroupAvailableVehicleResponse,
    availableOperator,
    availableVehicle,
    TaskGroupAvailableVehiclePayload,
    runAutoPlanification,
    listToPlan,
    TaskGroupRunAutoPlanificationPayload,
    TaskGroupRunAutoPlanificationResponse,
    VehiclePlanningsResponse,
    TaskgroupListAutoPlanificationProposalsPayload,
    TaskgroupListAutoPlanificationProposalsResponse,
    listAutoPlanificationProposals,
    TaskgroupApplyAutoPlanificationProposalsResponse,
    applyAutoPlanificationProposals,
    TaskgroupApplyAutoPlanificationProposalsPayload,
    TaskGroupTransferPayload,
    transfer,
    TaskGroupManuallyClosePayload,
    manuallyClose,
    TaskgroupCheckTaskGroupMobilicMissionResponse,
    checkMobilicMission,
    closeTaskgroup,
    TaskGroupUpdateErrorPayload,
    removeBatch,
    TaskGroupRemoveBatchPayload,
} from './api/taskGroups';
import { TaskGroup, TaskGroupService } from './api/types';
import { preBillingKeys } from './preBilling';

export const taskGroupsKeys = {
    all: ['taskGroups'],
    lists: () => [...taskGroupsKeys.all, 'list'],
    list: (params?: TaskGroupListPayload) => [...taskGroupsKeys.lists(), params],
    listToPlan: () => [...taskGroupsKeys.all, 'listToPlan'],
    details: () => [...taskGroupsKeys.all, 'details'],
    detail: (id?: TaskGroupIdPayload) => [...taskGroupsKeys.details(), id],
    listPoints: (id?: TaskGroupIdPayload) => [...taskGroupsKeys.all, 'points', id],
    listAllServices: () => [...taskGroupsKeys.all, 'services'],
    listServices: (params?: TaskGroupListServicesPayload) => [...taskGroupsKeys.listAllServices(), params],
    importFile: (params?: TaskGroupImportFilePayload) => [...taskGroupsKeys.all, params],
    listAvailableOperators: (params?: TaskGroupListAvailableOperatorsPayload) => [
        ...taskGroupsKeys.all,
        'availableOperators',
        params,
    ],
    listAvailableVehicles: (params?: TaskGroupListAvailableVehiclesPayload) => [
        ...taskGroupsKeys.all,
        'availableVehicles',
        params,
    ],
    runAutoPlanification: (params?: TaskGroupRunAutoPlanificationPayload) => [
        ...taskGroupsKeys.all,
        'runAutoPlanification',
        params,
    ],
    listAutoPlanificationProposals: (params?: TaskgroupListAutoPlanificationProposalsPayload) => [
        ...taskGroupsKeys.all,
        'listAutoPlanificationProposals',
        params,
    ],
    applyAutoPlanificationProposals: () => [...taskGroupsKeys.all, 'applyAutoPlanificationProposals'],
    availableOperator: (params?: TaskGroupAvailableOperatorPayload) => ['availableOperator', params],
    availableVehilcle: (params?: TaskGroupAvailableVehiclePayload) => ['availableVehicles', params],

    // availableVehilcle
    vehiclesPlanning: (params?: TaskGroupListPayload) => [...taskGroupsKeys.all, params],
    vehicleAvailabilities: (params: VehiclePlanningAvailabilitiesPayload) => [
        ...taskGroupsKeys.all,
        'vehicleAvailabilities',
        params,
    ],
    // check mobilic mission status
    checkTaskGroupMobilicMission: (id?: TaskGroupIdPayload) => [
        ...taskGroupsKeys.all,
        'checkTaskGroupMobilicMission',
        id,
    ],
};

export const useTaskGroupList = <TData = TaskGroupListResponse>(
    params?: TaskGroupListPayload,
    options?: UseQueryOptions<TaskGroupListResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupListResponse, AxiosError, TData>(
        taskGroupsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupToPlanList = <TData = TaskGroupListResponse>(
    options?: UseQueryOptions<TaskGroupListResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupListResponse, AxiosError, TData>(taskGroupsKeys.list(), async () => await listToPlan(), {
        keepPreviousData: true,
        ...options,
    });
};

export const useTaskGroupDetails = <TData = TaskGroup>(
    id?: TaskGroupIdPayload,
    options?: UseQueryOptions<TaskGroup, AxiosError, TData>
) => {
    return useQuery<TaskGroup, AxiosError, TData>(taskGroupsKeys.detail(id), async () => await details(id), options);
};

export const useTaskGroupCreate = (options?: UseMutationOptions<TaskGroup, AxiosError, TaskGroupCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroup, AxiosError, TaskGroupCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(taskGroupsKeys.lists());
        },
    });
};

export const useTaskGroupUpdate = (options?: UseMutationOptions<TaskGroup, AxiosError, TaskGroupUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroup, AxiosError<TaskGroupUpdateErrorPayload>, TaskGroupUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries to refetch for refreshing the list views
                queryClient.invalidateQueries(taskGroupsKeys.lists());

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(taskGroupsKeys.detail(variables.id));

                queryClient.invalidateQueries(preBillingKeys.listsTaskGroups());
                queryClient.invalidateQueries(preBillingKeys.details());

                // invalidate list queries to refetch for refreshing the list of taskgroup with toPlan status (auto planification only)
                queryClient.invalidateQueries(taskGroupsKeys.listToPlan());
            },
        }
    );
};

export const useTaskGroupRemove = (options?: UseMutationOptions<undefined, AxiosError, TaskGroupIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TaskGroupIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(taskGroupsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(taskGroupsKeys.lists());
        },
    });
};

export const useTaskGroupRemoveBatch = (
    options?: UseMutationOptions<undefined, AxiosError, TaskGroupRemoveBatchPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TaskGroupRemoveBatchPayload>(
        async (params) => await removeBatch(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries to refetch for refreshing the list views
                queryClient.invalidateQueries(taskGroupsKeys.lists());
            },
        }
    );
};

export const useTaskGroupListPoints = <TData = TaskGroupListPointsResponse>(
    params?: TaskGroupIdPayload,
    options?: UseQueryOptions<TaskGroupListPointsResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupListPointsResponse, AxiosError, TData>(
        taskGroupsKeys.listPoints(params),
        async () => await listPoints(params),
        options
    );
};

export enum ImportFileErrorsCodes {
    customerCoherenceError = 'customerCoherenceError',
    placeCoherenceError = 'placeCoherenceError',
    fileNameNotSupported = 'fileNameNotSupported',
}

export const useTaskGroupImportFile = (
    options?: UseMutationOptions<TaskGroup, AxiosError<{ code: ImportFileErrorsCodes }>, TaskGroupImportFilePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroup, AxiosError<{ code: ImportFileErrorsCodes }>, TaskGroupImportFilePayload>(
        async (params) => await importFile(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);
                queryClient.invalidateQueries(taskGroupsKeys.lists());
            },
        }
    );
};

export const useVehiclePlannings = <TData = VehiclePlanningsResponse>(
    params?: TaskGroupListPayload,
    options?: UseQueryOptions<VehiclePlanningsResponse, AxiosError, TData>
) => {
    return useQuery<VehiclePlanningsResponse, AxiosError, TData>(
        taskGroupsKeys.vehiclesPlanning(params),
        async () => await vehiclePlannings(params),
        options
    );
};

export const useVehiclePlanningAvailabilities = <TData = VehiclePlanningAvailabilitiesResponse>(
    params: VehiclePlanningAvailabilitiesPayload,
    options?: UseQueryOptions<VehiclePlanningAvailabilitiesResponse, AxiosError, TData>
) => {
    return useQuery<VehiclePlanningAvailabilitiesResponse, AxiosError, TData>(
        taskGroupsKeys.vehicleAvailabilities(params),
        async () => await vehicleAvailabilities(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupListServices = <TData = TaskGroupListServicesResponse>(
    params: TaskGroupListServicesPayload,
    options?: UseQueryOptions<TaskGroupListServicesResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupListServicesResponse, AxiosError, TData>(
        taskGroupsKeys.listServices(params),
        async () => await listServices(params),
        options
    );
};

export const useTaskGroupCreateService = (
    options?: UseMutationOptions<TaskGroupService, AxiosError, TaskGroupCreateServicePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroupService, AxiosError, TaskGroupCreateServicePayload>(
        async (params) => await createService(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(taskGroupsKeys.lists());
                queryClient.invalidateQueries(taskGroupsKeys.listAllServices());
                queryClient.invalidateQueries(preBillingKeys.listsTaskGroups());
            },
        }
    );
};

export const useTaskGroupListAvailableOperators = <TData = TaskGroupListAvailableOperatorsResponse>(
    params: TaskGroupListAvailableOperatorsPayload,
    options?: UseQueryOptions<TaskGroupListAvailableOperatorsResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupListAvailableOperatorsResponse, AxiosError, TData>(
        taskGroupsKeys.listAvailableOperators(params),
        async () => await listAvailableOperators(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupListAvailableVehicles = <TData = TaskGroupListAvailableVehiclesResponse>(
    params: TaskGroupListAvailableVehiclesPayload,
    options?: UseQueryOptions<TaskGroupListAvailableVehiclesResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupListAvailableVehiclesResponse, AxiosError, TData>(
        taskGroupsKeys.listAvailableVehicles(params),
        async () => await listAvailableVehicles(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupAvailableOperator = <TData = TaskGroupAvailableOperatorResponse>(
    params: TaskGroupAvailableOperatorPayload,
    options?: UseQueryOptions<TaskGroupAvailableOperatorResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupAvailableOperatorResponse, AxiosError, TData>(
        taskGroupsKeys.availableOperator(params),
        async () => await availableOperator(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupAvailableVehicle = <TData = TaskGroupAvailableVehicleResponse>(
    params: TaskGroupAvailableVehiclePayload,
    options?: UseQueryOptions<TaskGroupAvailableVehicleResponse, AxiosError, TData>
) => {
    return useQuery<TaskGroupAvailableVehicleResponse, AxiosError, TData>(
        taskGroupsKeys.availableVehilcle(params),
        async () => await availableVehicle(params),
        { keepPreviousData: true, ...options }
    );
};

export const useTaskGroupUnplan = (options?: UseMutationOptions<TaskGroup, AxiosError, TaskGroupIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroup, AxiosError, TaskGroupIdPayload>(async (params) => await unplan(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(taskGroupsKeys.lists());
            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(taskGroupsKeys.detail(variables));
            // invalidate list queries to refetch for refreshing the list of taskgroup with toPlan status (auto planification only)
            queryClient.invalidateQueries(taskGroupsKeys.listToPlan());
        },
    });
};

export const useTaskGroupTransfer = (options?: UseMutationOptions<TaskGroup, AxiosError, TaskGroupTransferPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroup, AxiosError, TaskGroupTransferPayload>(async (params) => await transfer(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(taskGroupsKeys.lists());
            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(taskGroupsKeys.detail(variables.taskGroupId));
        },
    });
};

export const useRunAutoPlanification = (
    options?: UseMutationOptions<
        TaskGroupRunAutoPlanificationResponse,
        AxiosError,
        TaskGroupRunAutoPlanificationPayload
    >
) => {
    const queryClient = useQueryClient();
    return useMutation<TaskGroupRunAutoPlanificationResponse, AxiosError, TaskGroupRunAutoPlanificationPayload>(
        async (params) => await runAutoPlanification(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                queryClient.invalidateQueries(taskGroupsKeys.listAutoPlanificationProposals());
            },
        }
    );
};

export const useListAutoPlanificationProposals = <TData = TaskgroupListAutoPlanificationProposalsResponse>(
    params: TaskgroupListAutoPlanificationProposalsPayload,
    options?: UseQueryOptions<TaskgroupListAutoPlanificationProposalsResponse, AxiosError, TData>
) => {
    return useQuery<TaskgroupListAutoPlanificationProposalsResponse, AxiosError, TData>(
        taskGroupsKeys.listAutoPlanificationProposals(params),
        async () => await listAutoPlanificationProposals(params),
        {
            keepPreviousData: true,
            ...options,
        }
    );
};

export const useApplyAutoPlanificationProposals = (
    options?: UseMutationOptions<
        TaskgroupApplyAutoPlanificationProposalsResponse,
        AxiosError,
        TaskgroupApplyAutoPlanificationProposalsPayload
    >
) => {
    const queryClient = useQueryClient();

    return useMutation<
        TaskgroupApplyAutoPlanificationProposalsResponse,
        AxiosError,
        TaskgroupApplyAutoPlanificationProposalsPayload
    >(async (params) => await applyAutoPlanificationProposals(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(taskGroupsKeys.lists());
        },
    });
};

export const useManuallycloseTaskGroup = (
    options?: UseMutationOptions<undefined, AxiosError, TaskGroupManuallyClosePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, TaskGroupManuallyClosePayload>(
        async (params) => await manuallyClose(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries to refetch for refreshing the list views
                queryClient.invalidateQueries(taskGroupsKeys.lists());
            },
        }
    );
};

export const useGetMobilicMissionStatus = <TData = TaskgroupCheckTaskGroupMobilicMissionResponse>(
    id?: TaskGroupIdPayload,
    options?: UseQueryOptions<TaskgroupCheckTaskGroupMobilicMissionResponse, AxiosError, TData>
) => {
    return useQuery<TaskgroupCheckTaskGroupMobilicMissionResponse, AxiosError, TData>(
        taskGroupsKeys.checkTaskGroupMobilicMission(id),
        async () => await checkMobilicMission(id),
        options
    );
};

export const useTaskGroupClose = (options?: UseMutationOptions<TaskGroup, AxiosError, TaskGroupIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TaskGroup, AxiosError, TaskGroupIdPayload>(async (params) => await closeTaskgroup(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(taskGroupsKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(taskGroupsKeys.detail(variables));

            queryClient.invalidateQueries(preBillingKeys.listsTaskGroups());
            queryClient.invalidateQueries(preBillingKeys.details());

            // invalidate list queries to refetch for refreshing the list of taskgroup with toPlan status (auto planification only)
            queryClient.invalidateQueries(taskGroupsKeys.listToPlan());
        },
    });
};
