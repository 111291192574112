import { Form, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { IntlShape } from 'react-intl';

import { DetailsFormCardProps } from '../../../components/form/DetailsFormCard';
import { sectionCardFormItemLayout } from '../../../components/SectionCard';
import ApiSelect from '../../../components/selects/ApiSelect';
import { formatDate } from '../../../i18n';
import genericMessages from '../../../i18n/genericMessages';
import operatorMessages from '../../../i18n/operatorMessages';
import { OperatorUpdatePayload } from '../../../queries/api/operators';
import { TransporterListPayload } from '../../../queries/api/transporters';
import { Operator, Transporter, ValueListItem, ValueListSlug } from '../../../queries/api/types';
import { useTransporterList } from '../../../queries/transporters';

export { default as OperatorsList } from './OperatorsList';
export { default as OperatorsValueList } from './OperatorsValueList';
export { default as OperatorsProfileForm } from './OperatorsProfileForm';
export { default as OperatorDetails } from './OperatorDetails';
export interface OperatorForm
    extends Omit<
        Operator,
        | 'id'
        | 'computedProperties'
        | 'contractStartedAt'
        | 'attachedTo'
        | 'contractType'
        | 'groups'
        | 'profile'
        | 'preferedSlots'
    > {
    contractStartedAt: Dayjs;
    attachedTo: ValueListItem['id'];
    contractType: ValueListItem['id'];
    groups: Array<ValueListItem['id']>;
    profile: ValueListItem['id'];
    preferedSlots: Array<ValueListItem['id']>;
}

export const formatOperatorInitialValues = (record: Operator | undefined) =>
    ({
        ...record,
        contractStartedAt: dayjs(record?.contractStartedAt),
        attachedTo: record?.attachedTo?.id,
        contractType: record?.contractType?.id,
        groups: record?.groups.map((group) => group.id),
        profile: record?.profile?.id,
        preferedSlots: record?.preferedSlots.map((slot) => slot.id),
    } as OperatorForm);

export const formatOperatorCreatePayload = (values: OperatorForm) => values;

export const formatOperatorUpdatePayload = (values: OperatorForm, operator?: Operator): OperatorUpdatePayload => ({
    ...values,
    firstName: values.firstName || operator?.firstName,
    lastName: values.lastName || operator?.lastName,
    attachedTo: values.attachedTo || operator?.attachedTo?.id,
    contractStartedAt: values.contractStartedAt || operator?.contractStartedAt,
    contractType: values.contractType || operator?.contractType?.id,
    groups: values.groups || operator?.groups,
    profile: values.profile || operator?.profile?.id,
    preferedSlots: values.preferedSlots || operator?.preferedSlots,
    transporter: values.transporter || '',
});

export const getOperatorFormSections = (formatMessage: IntlShape['formatMessage'], idlEntityId?: string) => {
    const sections: Array<
        Pick<
            DetailsFormCardProps<Operator, OperatorForm, OperatorUpdatePayload>,
            'title' | 'editButtonText' | 'fields' | 'renderEdit' | 'renderDetails'
        >
    > = [
        {
            title: formatMessage(operatorMessages.identity),
            editButtonText: formatMessage(genericMessages.editIdentity),
            fields: [
                {
                    name: 'lastName',
                    type: 'string',
                    label: formatMessage(genericMessages.lastName),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.lastNamePlaceholder),
                    },
                    required: true,
                },
                {
                    name: 'firstName',
                    type: 'string',
                    label: formatMessage(genericMessages.firstName),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.firstNamePlaceholder),
                    },
                    required: true,
                },
                {
                    name: 'mobilicEmail',
                    type: 'string',
                    label: formatMessage(genericMessages.email),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.emailPlaceholder),
                    },
                    required: true,
                    renderDetails: (_field, record) => <>{record?.isActive ? record.mobilicEmail : ''}</>,
                    renderEdit: (field, record) =>
                        record?.isActive || !record?.id ? (
                            <Form.Item
                                shouldUpdate
                                label={formatMessage(genericMessages.email)}
                                name="mobilicEmail"
                                {...sectionCardFormItemLayout}
                                required
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        ),
                },
                {
                    name: 'phone',
                    type: 'string',
                    label: formatMessage(genericMessages.phone),
                    fieldComponentProps: {
                        placeholder: formatMessage(genericMessages.phonePlaceholder),
                    },
                    required: true,
                    renderDetails: (_field, record) => <>{record?.isActive ? record.phone : ''}</>,
                    renderEdit: (field, record) =>
                        record?.isActive || !record?.id ? (
                            <Form.Item
                                shouldUpdate
                                label={formatMessage(genericMessages.phone)}
                                name="phone"
                                {...sectionCardFormItemLayout}
                                required
                            >
                                <Input />
                            </Form.Item>
                        ) : (
                            <></>
                        ),
                },
            ],
        },
        {
            title: formatMessage(genericMessages.groupLink),
            editButtonText: formatMessage(genericMessages.groupLinkEdit),
            fields: [
                {
                    name: 'transporter',
                    label: formatMessage(operatorMessages.transporter),
                    required: true,
                    formItemProps: {
                        dependencies: ['attachedTo'],
                    },
                    renderEdit: () => (
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue(['attachedTo']) &&
                                getFieldValue(['attachedTo']) !== idlEntityId && (
                                    <Form.Item
                                        label={formatMessage(operatorMessages.transporter)}
                                        name="transporter"
                                        {...sectionCardFormItemLayout}
                                        required
                                    >
                                        <ApiSelect<Transporter, Transporter['id'], TransporterListPayload>
                                            listQueryHandler={useTransporterList}
                                            valueProp="id"
                                            labelProp="name"
                                        />
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                    ),
                    renderDetails: (field, record) => record?.transporter?.name,
                    shouldRenderDetails: (field, record) => Boolean(record?.transporter),
                },
                {
                    name: 'groups',
                    required: true,
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        mode: 'multiple',
                        valueListSlug: ValueListSlug.groups,
                    },
                    label: formatMessage(genericMessages.groupLinkLong),
                },
            ],
        },
        {
            title: formatMessage(operatorMessages.profileAndContract),
            editButtonText: formatMessage(operatorMessages.editContract),
            fields: [
                {
                    name: 'profile',
                    required: true,
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        valueListSlug: ValueListSlug.operatorProfiles,
                    },
                    label: formatMessage(operatorMessages.profile),
                },
                {
                    name: 'contractType',
                    required: true,
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        valueListSlug: ValueListSlug.operatorContracts,
                    },
                    label: formatMessage(genericMessages.contractType),
                },
                {
                    name: 'contractStartedAt',
                    required: true,
                    type: 'DatePicker',
                    label: formatMessage(operatorMessages.contractStartedAt),
                    fieldComponentProps: {
                        format: 'DD/MM/YYYY',
                    },
                    renderDetails: (field: any, record: any, dataGetter: any) => {
                        const data = dataGetter(record, field?.name);
                        return formatDate(data);
                    },
                },
            ],
        },
        {
            title: formatMessage(operatorMessages.slots),
            editButtonText: formatMessage(operatorMessages.editSlots),
            fields: [
                {
                    name: 'preferedSlots',
                    required: false,
                    type: 'ValueListItemSelect',
                    fieldComponentProps: {
                        mode: 'multiple',
                        valueListSlug: ValueListSlug.operatorSlots,
                    },
                    label: formatMessage(operatorMessages.preferedSlots),
                },
            ],
        },
    ];

    return sections;
};
